<template>
  <div class="page-profile mb-4">
    <user-modal :type="'profile'" @userSaved="handleSaveProfile" :user-node="user"
                class="border-0 boxshadow card"></user-modal>
  </div>
</template>
<script>
import UserModal from "@/views/components/modals/UserModal";

export default {
  components: {UserModal},
  mounted() {
    this.mitt.emit('loadcomplete', { complete: true })
  },
  methods: {
    handleSaveProfile(user) {
      const olduser = this.$store.getters.getUser;
      this.$store.commit('setUser', {
        user: {...olduser, ...user}
      })
      localStorage.setItem('user', JSON.stringify(user));
    }
  }
}
</script>

<style lang="scss">
//.page-profile{
//.modal-content{
//
//}
//}
</style>